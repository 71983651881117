/* stylelint-disable no-descending-specificity */
@import '@core/styles/vars';

.YourAPIKeys {
  margin-bottom: var(--md);
  padding-top: var(--sm);

  // override table font-size
  :global(.Table tbody:not(.Table_spinner) td) {
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  // When API Key is expanded, we want to show the full API Key by letting cell wrap
  :global(.ObfuscatedAPIKey_expanded) > div {
    white-space: normal;
  }

  &-box {
    padding: 0;
  }

  &-table {
    width: 100%;

    :global(thead th.time) {
      width: 18%;
    }

    tr:hover .YourAPIKeys-button {
      opacity: 1;
    }

    td,
    th {
      &:last-child {
        text-align: center;
        width: 22px;
      }
    }
  }

  &-button {
    opacity: 0;
  }

  &-empty-button {
    --button-gap: 6px;

    margin-top: var(--xs);
  }

  &-empty-prompt {
    color: var(--color-text-minimum);
    font-size: 14px;
    padding: var(--sm);

    &-icon {
      color: var(--color-text-minimum-icon);
    }

    a {
      color: var(--color-text-minimum);
      text-decoration: underline;

      &:hover {
        color: var(--color-text-minimum-hover);
      }
    }
  }

  &-lastSeen {
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 150px;

    &-date {
      flex: 1;
    }
  }

  &-iconCheck {
    color: var(--green);
  }

  &-overflow {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
