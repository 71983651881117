.EmptyStatePrompt {
  color: var(--color-text-minimum);
  margin-top: var(--sm);

  &-label {
    font-size: 12px;
    font-weight: var(--font-weight);
  }

  a {
    color: var(--color-text-minimum);
    text-decoration: underline;
  }

  &-icon {
    color: var(--color-text-minimum-icon);
  }
}