@import '@core/styles/vars';

.MyRequestsHeader {
  backdrop-filter: blur(var(--md));
  background: rgba(var(--color-bg-page-rgb), 0.75);
  border-bottom: 1px solid var(--color-border-default);
  color: var(--color-text-minimum);
  font-size: 12px;
  font-weight: var(--font-weight);
  margin-bottom: var(--sm);
  padding: var(--sm) 0;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 3;

  &-insights-requests {
    padding-left: var(--sm);
  }

  &-timeRange {
    color: var(--color-text-minimum);
    font-size: 14px;
    margin-left: auto;
  }
}

.MyRequestsTable {
  overflow: auto;
}

.MyRequestsDateRange {
  margin-left: auto;
}
