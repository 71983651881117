@import '@core/styles/vars';

.GettingStarted {
  &-Header {
    margin-bottom: 10px;
  }

  &-Playground {
    box-shadow: none;
    flex: 0 0 var(--hub-main-max-width);
    max-width: var(--hub-main-max-width);

    @media (max-width: $ipad-pro-10-landscape) {
      flex: 0 0 calc(var(--hub-main-max-width) - 80px);
    }

    @media (max-width: $ipad-mini-portrait) {
      padding: 0;
    }

    &-Section {
      margin-bottom: 20px;
      padding: 0 0 10px;

      &-Params {
        margin-bottom: 20px;
      }
    }

    :global(.rm-PlaygroundRequest .CodeSnippet) {
      overflow: auto;
    }
  }

  &-Wrapper {
    display: grid;
    flex: 1;
    grid-template-areas: 'main sidebar' 'footer sidebar';
    grid-template-columns: 1fr var(--hub-playground-width);

    :global(.rm-Article.rm-Article) {
      // repeated .rm-Article to bump specificity
      grid-area: main;
      padding-bottom: 0;
      width: 100%;
    }

    .GettingStarted-Footer {
      grid-area: footer;
      padding: 0 30px;
    }

    @media (max-width: $ipad-mini-portrait) {
      display: flex;
      flex-direction: column;
    }
  }

  &-Sidebar {
    box-shadow: calc(var(--border-width) * -1) 0 0 var(--color-border-default);
    box-sizing: border-box;
    grid-area: sidebar;
    padding: var(--md);

    :global {
      .rm-PlaygroundResponse,.rm-APISectionHeader {
        margin-top: 0;
      }
    }

    @media (max-width: $ipad-mini-portrait) {
      box-shadow: none;
      grid-area: main;
      max-width: 100%;
      padding: 0 30px;
    }
  }
}

.SectionHeading {
  &-stepnumber {
    color: var(--gray60);
  }

  &-title {
    padding-left: 10px;
    padding-right: 10px;
  }

  i {
    color: var(--gray60);
  }
}

.rightColumn {
  box-shadow: calc(var(--border-width) * -1) 0 0 var(--color-border-default);
  box-sizing: border-box;
  flex: 0 0 var(--hub-playground-width);
  max-width: var(--hub-playground-width);
  padding: var(--md);

  :global(.rm-PlaygroundResponse) {
    margin-top: 0;
  }

  @media (max-width: $ipad-mini-portrait) {
    display: none;
  }
}

.MyRequests {
  padding: var(--sm) var(--md) var(--md);
  width: 100%;

  &-content {
    display: grid;
    gap: 2rem;
    grid-template-columns: 250px 1fr;

    &_min-height {
      min-height: 930px;
    }
  }
}
