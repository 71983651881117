@import '@core/styles/vars';

.Playground {
  box-shadow: calc(var(--border-width) * -1) 0 0 var(--color-border-default),
    var(--border-width) 0 0 var(--color-border-default);
  display: flex;
  flex: 0 0 var(--hub-playground-width);
  flex-direction: column;
  max-width: var(--hub-playground-width);
  padding-top: 15px;
  position: relative;

  @media (max-width: $ipad-pro-10-landscape) {
    flex: 0 0 calc(var(--hub-playground-width) - 80px);
  }

  @media (max-width: $ipad-mini-portrait) {
    box-shadow: 0 -1px 0 var(--color-border-default);
    box-sizing: border-box;
    flex: 0 0 auto;
    max-width: 100vw;
    padding: 20px 10px;
  }

  &-section {
    padding-left: $md;
    padding-right: $md;

    + .Playground-section {
      margin-top: $sm;

      &.Playground-section_sticky {
        margin-top: 0;
      }
    }

    &:empty {
      display: none;
    }

    &_sticky {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      max-height: 100vh;
      padding-bottom: $md;
      padding-top: $md;
      position: sticky;
      top: 0;

      @media (max-width: $ipad-mini-portrait) {
        height: auto;
      }
    }
  }
}
