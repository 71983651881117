@import '@core/styles/vars';
@import '@core/styles/mixins/ngz';
@import '@core/styles/mixins/spinner';
@include updated-at;

%fadeWrapper {
  transition: 0.3s;

  > * {
    transition: inherit;
  }
}

.rm-ReferenceMain {
  // this combination of different units below is what the margin is on non-basic pages so is
  // reproduced here for conformity in the mobile styling
  $margin-bottom-after-first-section: calc(10px + 1em);
  $_: #{&};

  @media (max-width: $ipad-mini-portrait) {
    flex-direction: column;
  }

  // most markdown css is marked with !important, but the specificity and !important tags below
  // should override those styles to make mobile view more consistent for basic pages
  &.rm-basic-page {
    @media (max-width: $ipad-mini-portrait) {
      .markdown-body {
        margin: 0 !important;

        &:last-child {
          margin: 0 !important;
        }

        &:first-child {
          margin: 0 !important;
        }
      }
    }
  }

  &Sidebar {
    align-self: start;
    color: var(--color-text-muted);
    font-family: var(--font-family);
    max-height: 100vh;
    overflow: auto;
    position: sticky;
    top: 0;
    width: var(--hub-sidebar-width);

    #hub-sidebar-content {
      padding-right: 0;
    }
  }

  &Endpoint {
    @extend %fadeWrapper;
  }

  &Playground {
    @extend %fadeWrapper;

    height: 100%;
    min-height: 100vh;
  }

  & .field-description a {
    word-break: break-word;
  }

  .rm-Article {
    max-width: var(--hub-main-max-width);

    @media (max-width: $ipad-pro-10-landscape) and (min-width: #{$ipad-mini-portrait + 1}) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  // SuperHub consumer handles displaying its loading state with `ui/Spinner` 
  &_loading:not(.rm-ReferenceMain-SuperHub) {
    .rm-Article {
      // Separate loading modifier to render the default spinner that is not
      // absolutely positioned by the `.loading` class below.
      @include spinner;
    }
  }
}

.Reference-section {
  margin-bottom: 1em;
}

.rm-Article,
.rm-Playground {
  transition: opacity var(--transition-fast) var(--transition-timing);
}

.loading {
  .rm-Article {
    @include spinner;

    // Spinner should be absolutely positioned to article, not fixed
    &::after {
      position: absolute;
      top: 30vh;
    }
  }

  .rm-Playground {
    opacity: 0.5;
    pointer-events: none;
  }
}
