.RequestsGraph {
  &-filter {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: var(--font-weight-bold);
  }

  &-container {
    padding: 10px;

    &_loading {
      align-self: center;
      display: flex;
      justify-content: center;
      min-height: 215px;
    }
  }

  &-graphWrapper {
    padding: 0;
  }

  &-graph {
    --MetricsGraph-padding: 0;
  }

  &-graphHeading {
    color: var(--color-text-minimum);
    font-size: 12px;
    font-weight: var(--font-weight);
    margin: 0 0 var(--sm);
  }

  &-totals {
    display: grid;
    font-size: 14px;
    gap: var(--xs);
    grid-template-columns: 1fr max-content;
    margin: var(--sm) 0 0;

    dt {
      align-items: center;
      color: var(--color-text-minimum);
      display: flex;
      gap: var(--xs);
    }

    dd {
      align-items: center;
      display: flex;
      justify-content: flex-end;
    }
  }

  &_empty dd {
    font-size: 12px;
    width: 80px;
  }
}
