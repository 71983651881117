@import '@core/styles/vars';

.RecipeContainer {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;

  &-item {
    box-sizing: border-box;
    flex: 0 1 calc(50% - 10px);
    margin: 5px;

    // The max-width values below were determined based on the min-width of the tile component,
    // which is set to 230px. If that needs to be tweaked at all, tweak these values as well!
    @media (max-width: 1300px) and (min-width: #{$ipad-pro-10-landscape + 1}),
      (max-width: 1011px) and (min-width: #{$ipad-mini-portrait + 1}),
      (max-width: 531px) {
      flex: 0 1 100%;
    }
  }
}
