@import '@core/styles/vars';
@import '~@core/styles/mixins/dark-mode';

.Main-QuickNav-container {
  box-sizing: border-box;
  padding: 15px 20px 10px; // this 15px top margin lines up with the 3rd column "Language"
  width: 100%;
}

.Footer {
  &-desktop {
    @media (max-width: $ipad-mini-portrait) {
      display: none;
    }
  }

  &-mobile {
    display: none;

    @media (max-width: $ipad-mini-portrait) {
      display: block;
      padding: 0 30px;
    }
  }
}

// This is only needed because of the old api explorer layout
// once everyone is on the redesign we can remove it!
hr.NextStepsDivider {
  margin-left: 0 !important;
}

// Temporarily for demo purposes
.TempBanner {
  @include dark-mode {
    background: rgba(black, 0.1);
    box-shadow: var(--box-shadow-menu-dark);
  }

  backdrop-filter: blur(var(--md));
  background: rgba(white, 0.1);
  border: 1px solid var(--color-border-default);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--box-shadow-menu-light);
  color: var(--color-text-default);
  font-size: 13px;
  margin-top: var(--sm);
  padding: 15px;
}

.OAuthRedirect {
  font-size: 16px;
  height: 100vh;

  &-heading,
  &-paragraph {
    margin: 0 auto;
    max-width: 20em;
    text-align: center;
  }

  &-paragraph {
    font-weight: var(--font-weight-normal);
  }
}
