.rm-UserAvatar {
  --UserAvatar-color: var(--color-primary-inverse, rgba($blue, 0.09));
  --UserAvatar-background: linear-gradient(
    180deg,
    var(--color-primary-darken-10, $blue-40) 0%,
    var(--color-primary, $blue) 100%
  );

  color: var(--UserAvatar-color);
}
