@import '@core/styles/vars';
@import '@core/styles/mixins/dark-mode';

.RealtimeTable {
  &-filter {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: var(--font-weight-bold);
  }

  &-table-wrapper {
    padding: 0;
  }

  &-table {
    width: 100%;

    tr td:first-child {
      padding-left: $sm;
    }

    tbody:not(.Table_spinner) {
      td {
        color: var(--color-text-default);
        font-size: 12px;
        font-weight: var(--font-weight);
        line-height: 1;

        &:only-child {
          text-align: center;
        }
      }
    }

    &-time {
      color: var(--color-text-minimum);
    }

    &-method {
      margin-right: $xs;
    }

    &-graph {
      display: flex;
      justify-content: flex-end;
      min-width: 100px;
      width: 100%;

      :global(canvas) {
        max-height: 25px;
        max-width: 100px;
      }
    }
  }

  &_topEndpoints:not(.RealtimeTable_empty) {
    tr {
      &:hover td,
      &:active td,
      &:focus td {
        @include dark-mode {
          background: rgba(white, 0.05);
        }

        background: rgba(black, 0.05);
        cursor: pointer;
      }
    }

    // path column should expand to fill available space
    :global(tr td:nth-child(1)) {
      max-width: 0;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
  }

  &_recentRequests:not(.RealtimeTable_empty) {
    tr {
      &:hover td,
      &:active td,
      &:focus td {
        @include dark-mode {
          background: rgba(white, 0.05);
        }

        background: rgba(black, 0.05);
        cursor: pointer;
      }
    }

    // prevent status code column from collapsing
    :global(tr td:first-child) {
      min-width: 42px;
    }

    // path column should expand to fill available space
    :global(tr td:nth-child(2)) {
      max-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    // prevent time column from collapsing
    :global(tr td:last-child) {
      min-width: 130px;
      padding-right: $sm;
    }
  }

  &_empty {
    :global(tr td) {
      min-width: 80px;
    }

    :global(tr td:first-child) {
      max-width: 0;
      width: 100%;
    }
  }

  &-emptyStateSparkline {
    margin-top: 5px;
  }

  &-emptyPrompt {
    color: var(--color-text-minimum);
    margin-top: var(--sm);

    &-label {
      font-size: 12px;
      font-weight: var(--font-weight);
    }

    a {
      color: var(--color-text-minimum);
      text-decoration: underline;
    }

    &-icon {
      color: var(--color-text-minimum-icon);
    }
  }

  :global(.HTTPStatus) {
    padding: 0;
  }
}
