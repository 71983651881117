.InfoTable {
  margin-top: var(--md);

  &-header {
    border-top: 1px solid var(--color-border-default);
    padding-top: var(--sm);
    width: 100%;
  }

  &-box-wrapper {
    padding: 0;
  }

  &-table td {
    font-size: 12px;
  }
}
