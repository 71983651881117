.TableContainer {
  &-body {
    text-align: center;
  }

  &-title {
    margin: var(--sm) 0;
  }

  &-text {
    line-height: 1.6;
    margin: 0;
  }
}
